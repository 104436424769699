import React from 'react'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactWidget from '../components/ContactWidget'
import Layout from '../components/Layout'

const ContactUs = () => {

    return (
        <Layout>
        <div className='container'>
        <section className="pt-5 pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-6 text-center mx-auto">
                        
                        <h6 className="text-primary">Contact us</h6>
                        <h1 className="mb-4">We're here to help!</h1>
                    </div>
                </div>
                
                
                <div className="row g-4 g-md-5 mt-0 mt-lg-3">
                    
                    <div className="col-lg-6 mt-lg-0">
                        <div className="card card-body shadow py-5 text-center h-100">
                            
                            <h5 className="mb-3">AF Academy</h5>
                            <ul className="list-inline mb-0">
                                
                                <li className="list-item mb-3 h6 fw-light">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 mt-1" />E-166, 2nd Floor, Kalkaji, New Delhi 110 019
                                </li>
                                
                                <li className="list-item mb-3 h6 fw-light">
                                    <FontAwesomeIcon icon={faPhoneAlt} className=" me-2"/>+91-9818568825, +91-9873556395 
                                </li>
                                
                                <li className="list-item mb-0 h6 fw-light">
                                    <FontAwesomeIcon icon={faEnvelope} className="me-2"/>info@aquafoundation.in, praggya@tunnelling.in
                                </li>
                            </ul>
                        </div>
                    </div>

                    
                    <div className="col-lg-6 mt-lg-0">
                        <div className="card card-body shadow py-5 text-center h-100">
                            
                            <h5 className="mb-3">Dr. Dhawan Academy for Geophysics</h5>
                            <ul className="list-inline mb-0">
                                
                                <li className="list-item mb-3 h6 fw-light">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 mt-1"/>DDAG Private Limited, 01, Shanker Nagar, Lucknow 226020 (UP) 
                                </li>
                                
                                <li className="list-item mb-3 h6 fw-light">
                                    <FontAwesomeIcon icon={faPhoneAlt} className="me-2"/>+91 9325595361 
                                </li>
                                
                                <li className="list-item mb-0 h6 fw-light">
                                    <FontAwesomeIcon icon={faEnvelope} className="me-2"/>ashumathurddag@gmail.com 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section >
            <div className="container">
                <div className="row g-4 g-lg-0 align-items-center">

                    <div className="col-md-12">
                        <h2 className="mt-4 mt-md-0">Write to us</h2>
                        <p>Have any queries regarding the course or for multiple participant registration.</p>
                            
                        <ContactWidget />
                    </div>
                    
                </div>
            </div>
        </section>
        </div>
        </Layout>
    )

}

export default ContactUs