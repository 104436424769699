import React, { useState } from 'react'
import { Form, Label, Input, Button } from 'reactstrap'
import { BASE_URL } from '../api-config'

const ContactWidget = () => {
    const [formData, setFormData] = useState({name: '', email: '', message: ''})
    const [errors, setErrors] = useState({name: false, email: false, message: false})
    const [formValid, setFormValid] = useState(true)
    const [submitStatus, setSubmitStatus] = useState('')

    const onValueChange = (event) => {
		// console.log(event.target.name, event.target.value);
        let _formData = formData
        let _errors = errors
        let _formValid = formValid
        setSubmitStatus('')

		_formData[event.target.name] = event.target.value;

		if (event.target.name === "name" && event.target.value.trim() === '') {
			_errors.name = true;
		} else {
			_errors.name = false;
		}
        if (event.target.name === "message" && event.target.value.trim() === '') {
			_errors.message = true;
		} else {
			_errors.message = false;
		}
		if (event.target.name === "email" && event.target.value.trim() === '') {
            _errors.email = true
        } else {
            _errors.email = false
        }
		for(const e of Object.keys(errors)) {
			if (errors[e]) {
				_formValid = false;
				break;
			}
		}
        // console.log(_errors, _formData, _formValid)
		setErrors(_errors)
        setFormData(_formData)
        setFormValid(_formValid)
    }

    // const validateEmail = (email) => {
    //     let isValid = false
    //     /* eslint-disable-next-line */
	// 	const expression = new RegExp("^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$");
    //     if (expression.test(String(email).trim().toLowerCase())) {
    //         isValid = true
    //     }
    //     return isValid
    // }
    
    const onFormSubmit = (e) => {
        e.preventDefault();
        if (formData.name.trim() === '' || formData.email.trim() === '' || formData.message.trim() === '') {
            setFormValid(false)
            setSubmitStatus('error')
            return 
        } else {
            setFormValid(true)
            setSubmitStatus('')
        }
		if (formValid) {
            setSubmitStatus('submitting')
            let _formData = {
                data: {
                    name: formData.name,
                    email: formData.email,
                    message: formData.message
                },
                meta:  {
                    metadata: {
                        "timezone" : (new window.Intl.DateTimeFormat().resolvedOptions().timeZone),
                        "offset" : (new Date()).getTimezoneOffset(),
                        "referrer" : document.referrer,
                        "browserName" : navigator.appName,
                        "userAgent" : navigator.userAgent,
                        "pathName" : window.location.href || '',
                        "onLine" : navigator.onLine,
                        "ipAddress": '',
                    },
                    formSlug: window.location.host
                }
            };

            // const prod = process.env.NODE_ENV === 'production';
            // const BASE_URL = prod ? 'https://api.hummz.net/v2' : 'http://localhost:3000/v2'

            fetch( BASE_URL + '/forms/response/885VxWEexFPhiI6nC6j35w', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(_formData)
                } )
                .then( ( response ) => {
                    // console.log(response);
                    return response.json();
                })
                .then( ( responseJson ) => {
                    // console.log('resolving successfully');
                    setSubmitStatus('success')
                } )
                .catch( ( error ) => {
                    console.error( error );
                    setSubmitStatus('submiterror')
                } );
		}
    }

    if (submitStatus === 'success') {
        return <div className="h5 fw-bolder rounded-4 py-3 w-100 px-5 bg-success text-white text-center">Thank you for contacting us. We will get back to you soon.</div>
    } else {
        return (
            <Form>
                <div className="mb-4 bg-light-input">
                    <Label className="form-label">Your name *</Label>
                    <Input type="text" required={true} className="form-control" name="name" id="name" defaultValue={formData.name} onChange={onValueChange}/>
                </div>
                <div className="mb-4 bg-light-input">
                    <Label className="form-label">Email address *</Label>
                    <Input type="email" required={true} className="form-control" name="email" id="email" defaultValue={formData.email} onChange={onValueChange}/>
                </div>
                <div className="mb-4 bg-light-input">
                    <Label className="form-label">Message *</Label>
                    <Input required={true} className="form-control" name="message" id="message" rows="4" type="textarea" defaultValue={formData.message} onChange={onValueChange} />
                </div>
                <div className="d-grid">
                    <Button className="btn btn-lg btn-success mb-0" disabled={submitStatus !== ''} onClick={onFormSubmit}>Send Message</Button>
                </div>	
            </Form>
        )
    }
}

export default ContactWidget